import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Grid, TextField, Button } from "@material-ui/core";
import { validateEmail, updateData, getDate, searchDataByValue } from "../../../resources/js/functions";

export default function FormLogin(props) {
  const { activeView } = props;
  const [campos, setCampos] = useState({});
  const [error, setError] = useState({ noUser: false, correo: false });
  const navigate = useNavigate();

  const validar = async (e) => {
    e.preventDefault();
    if (campos.correo) {
      if (!validateEmail(campos.correo)) {
        setError({ ...error, correo: "Ingresa un correo valido" });
        return null;
      }
    } else {
      setError({ ...error, correo: "El campo correo electrónico esta vacio." });
      return null;
    }

    const minusculas = campos.correo.toLowerCase();
    const result = await searchDataByValue('users', 'correo', minusculas);
    if (Object.values(result).length > 0) {
      updateData("users", result.id, { ultimaConexion: getDate() })
        .then(() => {
          localStorage.setItem("3c393f98", result.id);
          navigate("/home");
        })
        .catch((e) => console.log(e));
    } else {
      Swal.fire({
        title: "Error",
        text: "Correo no registrado",
        icon: "error",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#51647D",
      }).then((result) => {
        if (result.isConfirmed) {
          activeView(2)
        } 
      });

    }
  };

  return (
    <Grid item xs={12} sm={6} md={7} lg={7}>
      <form onSubmit={validar}>
        <div className="container-form-login">
          <div className="">
            <div className="text-form">Inicia sesión</div>
            {error.noUser && (
              <div className="error" style={{ marginBottom: "30px" }}>
                <i className="fas fa-exclamation-triangle" /> El usuaro no se encuentra registrado, contacta un admnistrador
              </div>
            )}
            <TextField autoComplete="true" type="email" fullWidth size="small" label="Correo electrónico" variant="filled" onChange={(e) => setCampos({ ...campos, correo: e.target.value })} />
            &nbsp;
            {error.correo && (
              <div className="error">
                <i className="fas fa-exclamation-triangle" /> {error.correo}
              </div>
            )}
            &nbsp;
            <div className="full space-footer-form">
              <Button className="btn btn-one" type="submit">
                Iniciar sesión
              </Button>
              
              <span className="register">
                ¿Aún no tienes cuenta? &nbsp;&nbsp;&nbsp;
                <span className="register-text" onClick={() => activeView(2)}>
                  Regístrate
                </span>
              </span>
            </div>
            {/* <div className="center full reset-password" onClick={() => activeView(3)}>
              ¿Olvidaste tu contraseña?
            </div> */}
          </div>
        </div>
      </form>
    </Grid>
  );
}
