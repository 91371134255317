export default function ValidateUser(props) {
  const { children } = props;
  const data = localStorage.getItem("3c393f98");
  let content = "";

  if (
    data === "l2rwm5a9" ||
    data === "l31v75wm"
  ) {
    content = <div>{children}</div>;
  } else {
    window.location.replace("/home");
  }
  return content;
}
