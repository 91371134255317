import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ref, onChildChanged } from "firebase/database";
import { db } from "../../../../config/firebase";
import AliceCarousel from "react-alice-carousel";
import { Button } from "@material-ui/core";
import orderby from 'lodash.orderby';
import { top, getCollections } from '../../../../resources/js/functions';
import "react-alice-carousel/lib/alice-carousel.css";

export default function Carousel() {
  const navigate = useNavigate();
  const [conferencias, setConferencias] = useState([]);

  useEffect(() => {
    getConferencias();
    const dbRef = ref(db, "conferencias/");
    onChildChanged(dbRef, (data) => {
      getConferencias();
    });
  }, []);


  const getConferencias = async () => {
    const array = [];
    const data = await getCollections('conferencias', 'carousel', true);
    Object.values(data).map(val => {
      if (val.carousel && val.mes === "SEPTIEMBRE" || val.carousel && val.mes === "OCTUBRE") {
        array.push(val);
      }
    })
    const order = orderby(array, ['id'], ['desc']);
    setConferencias(order);
  }

  return (
    <AliceCarousel
      mouseTrackingEnabled
      items={conferencias}
      autoPlayInterval="4000"
      // autoPlay={true}
      infinite={true}
      mouseTracking
      startIndex={0}
      slideToIndex={0}
      disableButtonsControls
      // disableDotsControls
      responsive={{
        425: {
          items: 2,
        },
        640: {
          items: 2,
        },
        1024: {
          items: 3,
        },
      }}
    >
      {conferencias.map((i, index) => {
        return (
          <div class="fullwrap">
            {/* <iframe width="100%" height="240" title="streaming" src={i.urlVideo} allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming" /> */}
            <img src={i.portada} alt="portada" className="full" />

            <div class="fullcap">
              <div className="titulo">
                {i.nombreConferencia}
              </div>
              <Button className="btn btn-one" onClick={() => navigate(`/streaming/${i.idUniqid}`)}>Ver</Button>
            </div>
          </div>
        );
      })}
    </AliceCarousel>
  );
}
