import { BrowserRouter, Route, Routes } from "react-router-dom";
// Vistas
import ComingSoon from "../views/ComingSoon";
import Login from "../views/Login";
import Home from "../views/Home";
import Cursos from "../views/Cursos";
import AboutUs from "../views/AboutUS";
import Speakers from "../views/Speakers";
import StreamingOnLine from "../views/StreamingOnLine";
import Adminstration from "../views/Adminstration";
import Pruebas from "../views/Pruebas";
import PanelChat from "../components/PanelChat";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        {/* <Route exact path="/login-pruebas" element={<Login />} /> */}
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/talleres" element={<Cursos />} />
        <Route exact path="/conferencistas" element={<Speakers />} />
        <Route exact path="/streaming/:id" element={<StreamingOnLine />} />
        <Route exact path="/conocenos" element={<AboutUs />} />
        <Route exact path="/panel-admin-connect" element={<Adminstration />} /> 
        <Route exact path="/panel-pruebas-connect" element={<Pruebas />} /> 
        <Route exact path="/panel-chat" element={<PanelChat />} /> 
        {/* 
        */}

        {/* 
        <Route exact path="/libro-de-visitas" element={<Guestbook />} />
        <Route exact path="/comentarios" element={<Comments />} />
        <Route exact path="/reportes-connect" element={<Reporte />} />
       */}
      </Routes>
    </BrowserRouter>
  );
}
