import React, { useState, useEffect } from "react";
import { getCollections } from "../../resources/js/functions";
import "./style.scss";

export default function Buscar(props) {
  const { busquedaProps } = props;
  const [buscar, setBuscar] = useState("");
  const [conferencias, setConferencias] = useState([]);
  let filteredConferencias = [];

  useEffect(() => {
    getConferencias();
  }, []);

  const getConferencias = async () => {
    const conferencias = await getCollections("conferencias");
    setConferencias(conferencias);
  };

  async function buscarChange(e) {
    setBuscar(e.target.value);
    if (e.target.value.length < 3) {
      const conferencias = await getCollections("conferencias");
      const value = Object.values(conferencias);
      busquedaProps(value);
    } else {
      filteredConferencias = conferencias.filter((val) => {
        return val.nombreConferencia.toLowerCase().indexOf(buscar.toLowerCase()) !== -1;
      });
      if (Object.keys(filteredConferencias).length < 1 || buscar.length < 2) {
        busquedaProps([]);
      } else {
        busquedaProps(filteredConferencias);
      }
    }
  }

  return (
    <>
      <div className="input-icons">
        <i className="fa fa-search icon"></i>
        <input
          onBlur={Object.keys(filteredConferencias).length < 1 ? () => getConferencias() : () => busquedaProps(filteredConferencias)}
          type="text"
          className="input-search"
          placeholder="Ingresa el nombre de la conferencia para filtrarla..."
          value={buscar}
          onChange={buscarChange}
        />
      </div>
    </>
  );
}
