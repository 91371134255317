import React, { useState, useEffect } from "react";
import { db } from "../../config/firebase";
import { ref, onChildChanged } from "firebase/database";
import { Grid, Container, Switch, Button } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import InputSearch from "../../components/InputSearch";
import ValidateAdmin from "../../components/ValidateAdmin";
import { getCollections, updateData, getDate, stringToArray, cualMes } from "../../resources/js/functions";
import { reporteUsuarios } from "./functionsReportes";
import { JsonToExcel } from "react-json-to-excel";
import "./styles.scss";

export default function Administration() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getConferences();
    const dbRef = ref(db, "conferencias/");
    onChildChanged(dbRef, (data) => {
      getConferences();
    });
  }, []);

  const getConferences = async () => {
    const conferencias = await getCollections("conferencias");
    setData(conferencias);
  };

  const busquedaFunction = (busqueda) => {
    setData(busqueda);
  };


  const activarConferencia = (id) => {
    const infoConferencia = data.filter((val) => val.idUniqid === id);
    const info = {
      carousel: !infoConferencia[0].carousel,
    };
    Swal.fire({
      title: `${infoConferencia[0].activa ? "¿Deseas desactivar el taller?" : "¿Deseas activar taller?"}`,
      showDenyButton: true,
      confirmButtonText: "Confirmar",
      confirmButtonColor: "#f38f04",
      cancelButtonColor: "#e10613",
      denyButtonText: `Cancelar`,
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        updateData("conferencias", id, info).then(() => {
          // window.location.reload()
        });
      }
    });
  };

  return (
    <ValidateAdmin>
      <Header />
      <div id="administration">
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={11} lg={11}>
              <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="btn btn-one cursor full" onClick={() => reporteUsuarios()}>Usuarios</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={11} lg={11}>
              <InputSearch busquedaProps={busquedaFunction} />
            </Grid>
            <Grid item xs={12} sm={12} md={11} lg={11}>
              {Object.values(data).length > 0 ? (
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow className="abner">
                        <TableCell>
                          <div className="titulos titulo-encuesta">Conferencia</div>
                        </TableCell>
                        <TableCell>
                          <div className="titulos titulo-encuesta">Lanzamiento</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className="titulos titulo-encuesta">Activar taller</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.values(data).map((row) => {
                        const fecha = stringToArray(row.fecha, '-');
                        const fechaArreglada = `${fecha[0]}-${cualMes(parseInt(fecha[1]))}-${fecha[2]}`
                        return <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            <div className="nombre-conferencia">{row.nombreConferencia}</div>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <div className="nombre-conferencia">{fechaArreglada}</div>
                          </TableCell>
                          <TableCell>
                            <div className="full center">
                              <Switch checked={row.carousel} onChange={() => activarConferencia(row.idUniqid)} color="primary" />
                            </div>
                          </TableCell>
                        </TableRow>
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="full center">
                  <div className="titulos titulo-encuesta">Sin resultados</div>
                </div>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </ValidateAdmin>
  );
}
