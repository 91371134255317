import React, { useEffect } from "react";
import { top } from '../../resources/js/functions';
import Header from "../../components/Header";
import ValidateUser from "../../components/ValidateUser";
import Footer from "../../components/Footer";
import SectionWelcome from "./SectionWelcome";
import SectionWhatSeeToday from "./SectionWhatSeeToday";
import SectionConferencesToday from "./SectionConferencesToday";
import SectionMayo from "./SectionMayo";
import SectionJunio from "./SectionJunio";
import SectionJulio from "./SectionJulio";
import SectionAgosto from "./SectionAgosto";
import SectionSeptiembre from "./SectionSeptiembre";
// import BG from "../../resources/images/general_bg.png";
import "react-alice-carousel/lib/alice-carousel.css";
import "./styles.scss";

export default function Home() {
  useEffect(() => {
    top();
  }, [])

  // let backgroundStyles = `#root::after{background-image: url(${BG})};`;
  return (
    <>
      <ValidateUser>
        <Header />
        {/* <style>{backgroundStyles}</style> */}
        <div id="home">
          <SectionWelcome />
          <SectionConferencesToday />
          <SectionMayo />
          <SectionJunio />
          <SectionJulio />
          <SectionAgosto />
          <SectionSeptiembre />
          <SectionWhatSeeToday />
          {/* 
          <SectionsSocios /> 
          */}
        </div>
        <Footer />
      </ValidateUser>
    </>
  );
}
