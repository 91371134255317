import React from "react";
import { Grid, Container } from "@material-ui/core";
import SectionConferenceActive from "../SectionConferenceActive";

export default function SectionWelcome(props) {
  const { conference } = props;
  // console.log(conference);
  return (
    <Container maxWidth="lg" className=" wow fadeIn" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
      <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <h1>
            ¡Bienvenido a tu programa Bienestar con Mindfulness!
          </h1>
          <p>
            Nos alegra muchísimo tenerte aquí. Estamos seguros de que este programa será el inicio de una era de autocuidado y bienestar.
          </p>
          <p>
            Déjate guiar por nuestro equipo de maestros certificados, ellos te acompañarán durante los siguientes meses y te brindarán las herramientas clave para desarrollar tu máximo potencial y que descubras que puedes vivir una vida mucho más plena y feliz.
          </p>
          <p> Este espacio es tuyo y lo puedes consultar las veces que quieras.
          </p>
          <p>
            ¡Aprovéchalo al máximo!
          </p>
          
        </Grid>
      </Grid>
      <SectionConferenceActive conference={conference} />
      {/* <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <div className="contenedor-streaming">
            <iframe title="streaming" src="https://player.vimeo.com/video/706729600?h=3457956db3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming"></iframe>
          </div>
        </Grid>
      </Grid> */}
    </Container>
  );
}

