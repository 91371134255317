import React, { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Swal from "sweetalert2";
import uniqid from "uniqid";
import { setData, getDate, searchDataByValue, validateEmail } from "../../../resources/js/functions";
import PoliticasPrivacidad from '../../../resources/descargables/Politicas-de-privacidad.pdf'

export default function FormReset(props) {
  const [campos, setCampos] = useState({});
  const { activeView } = props;

  const registrar = async (e) => {
    e.preventDefault();
    // console.log("hola");
    const existCorreo = await searchDataByValue("users", "correo", campos.correo);
    let json = {};
    
    if (Object.keys(existCorreo).length > 0) {
      Swal.fire({
        title: "¡Error!",
        text: "Este correo electrónico ya se encuentra registrado",
        icon: "warning",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#51647D",
      });
    } else {
      if (Object.keys(campos).length < 4) {
        Swal.fire({
          title: "¡Error!",
          text: "Existen campos vacios",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#51647D",
        });
      } else {
        if (!validateEmail(campos.correo)) {
          Swal.fire({
            title: "¡Error!",
            text: "Por favor ingresa un correo valido",
            icon: "error",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#51647D",
          });
        } else {
          if (!campos.politicas) {
            Swal.fire({
              title: "¡Error!",
              text: "Por favor acepta los términos y condiciones",
              icon: "warning",
              confirmButtonText: "Cerrar",
              confirmButtonColor: "#51647D",
            });
          } else {
            const id = uniqid();
            const minusculas = campos.correo.toLowerCase();
            json = {
              id,
              correo: minusculas,
              ...campos,
              fechaDeRegistro: getDate(),
              ultimaConexion: ''
            }

            setData('users', id, json).then(() => {
              Swal.fire({
                title: "¡Bien hecho!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#51647D",
              }).then((result) => {
                if (result.isConfirmed) {
                  activeView(1);
                }
              });
            }).catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              json[id] = {
                id,
                correo: minusculas,
                ...campos,
                fechaDeRegistro: getDate(),
                ultimaConexion: '',
                log: "error de registro",
                errorMessage,
                errorCode
              }
              setData('log', id, json)
              console.log("Error #" + errorCode);
              console.log("Error " + errorMessage);
            });

          }
        }
      }
    }
  };

  return (
    <Grid item xs={12} sm={6} md={7} lg={7}>
      <div className="form-registro">
        <div className="container-form-login">
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="text-form">Regístrate</div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField fullWidth size="small" required label="Nombre" variant="filled" onChange={(e) => setCampos({ ...campos, nombre: e.target.value })} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField fullWidth size="small" required label="Apellido" variant="filled" onChange={(e) => setCampos({ ...campos, apellido: e.target.value })} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField fullWidth size="small" required label="Correo" type="email" variant="filled" onChange={(e) => setCampos({ ...campos, correo: e.target.value })} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField fullWidth size="small" required label="Cargo" variant="filled" onChange={(e) => setCampos({ ...campos, cargo: e.target.value })} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormGroup>
                <FormControlLabel control={<Checkbox value={campos.politicas} onChange={() => setCampos({ ...campos, politicas: !campos.politicas })} />} label="*Acepto términos y condiciones conforme a la política de tratamiento de datos universal." />
                <a className="hijos" style={{ color: "#51647D", textDecoration: "underline", fontSize: '12px' }} href={PoliticasPrivacidad} rel="noreferrer" target="_blank">
                  Ver más
                </a>
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} className="padre enter">
              <div className="btn btn-one hijos" onClick={registrar}>
                <span>Registrarse</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} className="padre center">
              <span className="register-text hijos cursor" style={{ color: "#51647D", fontSize: '14px' }} onClick={() => activeView(1)}>
                Iniciar Sesión
              </span>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
}
