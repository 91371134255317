import React from 'react';
import Chat from '../../components/ChatPersonal';
import { Grid, Container, Button } from "@material-ui/core";
import ValidateUser from '../../components/ValidateUser';


export default function Pruebas() {
    return <ValidateUser><Container maxWidth="lg">
        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
                <Chat idConferencia='b76h9215l31otamc' />
            </Grid>
        </Grid>
    </Container></ValidateUser>
}