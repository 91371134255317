import { db } from '../../config/firebase';
import { ref, onValue, set, update, remove, onChildChanged, orderByChild, equalTo, get, query } from 'firebase/database';
let xlsx = require('json-as-xlsx');

//--------------------------------------------------------------------------------------//
//-----------------------------FUNCIONES DE BASE DE DATOS-------------------------------//
//--------------------------------------------------------------------------------------//

// leer datos
export const getCollections = async (collection) => {
	let data = {};
	const dataSnapshot = await get(query(ref(db, `${collection}`)));
	if (dataSnapshot.exists()) {
		data = Object.values(dataSnapshot.val());
	}
	return data;
};

// leer datos
export const getCollectionsComplete = async (collection) => {
	let data = {};
	const dataSnapshot = await get(query(ref(db, `${collection}`)));
	if (dataSnapshot.exists()) {
		data = dataSnapshot.val();
	}
	return data;
};

// set datos
export const setData = async (collection, id, json) => {
	set(ref(db, `${collection}/${id}`), json);
};

// buscar dato por id
export const searchData = async (collection, id) => {
	let json = {};
	const dbRef = ref(db, `${collection}/${id}`);
	onValue(dbRef, (snapshot) => {
		if (snapshot.exists()) {
			json = snapshot.val();
			// console.log(snapshot.val());
		}
	});
	// console.log(json);
	return json;
};

// buscar dato por valor
export const searchDataByValue = async (collection, key, value) => {
	let data = {};
	const dataSnapshot = await get(query(ref(db, `${collection}`), orderByChild(`${key}`), equalTo(`${value}`)));
	if (dataSnapshot.exists()) {
		data = Object.values(dataSnapshot.val())[0];
	}
	return data;
};

// actualizar datos
export const updateData = async (collection, id, json) => {
	update(ref(db, `${collection}/${id}`), json);
};

// borrar datos
export const deleteData = async (id) => {
	remove(ref(db, 'conferencias/' + id))
		.then(() => {
			console.log('eliminado correctamente');
		})
		.catch((e) => {
			console.log(e);
		});
};

// child changed
export async function childChange(collection) {
	let json = {};
	const collectionRef = ref(db, `${collection}/`);
	onChildChanged(collectionRef, (data) => {
		json = data.val();
	});
	return json;
}

//--------------------------------------------------------------------------------------//
//-----------------------------FUNCIONES GENERALES--------------------------------------//
//--------------------------------------------------------------------------------------//

function quitarAcentos(cadena) {
	const acentos = {
		á: 'a',
		é: 'e',
		í: 'i',
		ó: 'o',
		ú: 'u',
		Á: 'A',
		É: 'E',
		Í: 'I',
		Ó: 'O',
		Ú: 'U',
		Ñ: 'n',
		ñ: 'n',
	};
	return cadena
		.split('')
		.map((letra) => acentos[letra] || letra)
		.join('')
		.toString();
}

export function cambiarTextos(string) {
	const minusculas = string.toLowerCase();
	const sinEspacios = minusculas.replace(' ', '-');
	const sinTildes = quitarAcentos(sinEspacios);
	return sinTildes;
}

export function stringToArray(string, separador = ',') {
	const array = string.split(separador);
	return array;
}

// export function capitalize(string) {
//   const resultado = string.charAt(0).toUpperCase() + string.slice(1);
//   return resultado;
// }

export function cualMes(id) {
	let mes = '';
	switch (id) {
		case 1:
			mes = 'Enero';
			break;
		case 2:
			mes = 'Febrero';
			break;
		case 3:
			mes = 'Marzo';
			break;
		case 4:
			mes = 'Abril';
			break;
		case 5:
			mes = 'Mayo';
			break;
		case 6:
			mes = 'Junio';
			break;
		case 7:
			mes = 'Julio';
			break;
		case 8:
			mes = 'Agosto';
			break;
		case 9:
			mes = 'Septiembre';
			break;
		case 10:
			mes = 'Octubre';
			break;
		case 11:
			mes = 'Noviembre';
			break;
		case 12:
			mes = 'diciembre';
			break;

		default:
			break;
	}
	return mes;
}

export function getDate() {
	const today = new Date();
	const date = `${today.getFullYear()}-${today.getMonth() < 10 ? `${today.getMonth() + 1}` : today.getMonth() + 1}-${
		today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
	} ${today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()}:${today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()}:${
		today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()
	} ${today.getHours() < 12 ? 'am' : 'pm'}`;
	return date;
}

export function validateEmail(email) {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

export function setFavicons(favImg) {
	const headTitle = document.querySelector('head');
	const setFavicon = document.createElement('link');
	setFavicon.setAttribute('rel', 'shortcut icon');
	setFavicon.setAttribute('href', favImg);
	headTitle.appendChild(setFavicon);
}

export function downloadFile(file, name) {
	const link = document.createElement('a');
	link.href = `${file}`;
	link.download = `${name}`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

// export function capitalizeFirstLetter(string) {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }

export function top() {
	window.scrollTo(0, 0);
}

export async function reporte() {
	const results = await getCollections('usuarios');
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Usuarios',
			columns: [
				{ label: 'ID', value: 'id' }, // Top level data
				{ label: 'Nombre', value: 'nombre' }, // Top level data
				{ label: 'Apellidos', value: 'apellido' }, // Top level data
				{ label: 'Empresa', value: 'empresa' }, // Top level data
				{ label: 'Correo', value: 'correo' }, // Top level data
				{ label: 'Número de teléfono', value: 'numTelefono' }, // Top level data
				{ label: 'Calle, # exterior e interior', value: 'calleNum' }, // Top level data
				{ label: 'Colonia, alcaldia o municipio', value: 'colonia' }, // Top level data
				{ label: 'Ciudad', value: 'ciudad' }, // Top level data
				{ label: 'Código postal', value: 'codigoPostal' }, // Top level data
				{ label: 'Referencia del domicilio', value: 'refDomicilio' }, // Top level data
				{ label: 'Fecha de registro', value: 'fechaRegistro' }, // Top level data
				{ label: 'Ultima conexón', value: 'ultimaConexion' }, // Top level data
			],
			content: results,
		},
	];

	let settings = {
		fileName: `Usuarios-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}
