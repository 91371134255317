import React, { useRef, useState, useEffect } from 'react';
import { ref, onChildChanged } from "firebase/database";
import SendIcon from '@mui/icons-material/Send';
import Swal from "sweetalert2";
import uniqid from 'uniqid';
import { db } from "../../../config/firebase";
import { getCollections, getDate, setData, updateData } from '../../../resources/js/functions';
import orderby from 'lodash.orderby';
import Avatar from '../../../resources/images/chat/avatar.jpeg';
import Logo from '../../../resources/images/chat/spira-logo.svg';
import './styles.scss';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
const admins = ["l2rwm5a9"];
const NombreAdmin = "Spira Mexico";

export default function ChatPersonal(props) {
    const { idConferencia, userID } = props;
    console.log('====================================');
    console.log(props);
    console.log('====================================');
    return <div id='chat'>
        <div className='App'>
            <div className='titulo-chat'>
                <div>Chat</div>
            </div>
            <section>
                <ChatRoom idConferencia={idConferencia} userID={userID} />
            </section>
        </div>
    </div>
}

function ChatRoom(props) {
    const { idConferencia, userID } = props;
    const [formValue, setFormValue] = useState('');
    const [messages, setMessages] = useState([]);
    const dummy = useRef();

    const vetarUsuario = async (idUser) => {
        Swal.fire({
            title: "¿Deseas borrar mensajes de este usuario?",
            icon: "warning",
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#51647D",
            showCancelButton: true,
            cancelButtonText: 'cerrar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const array = [];
                const json = {};
                const dataMessages = await getCollections(`chat/${idConferencia}`);
                Object.values(dataMessages).map(val => {
                    if (val.user === idUser) {
                        array.push({ ...val, visible: false });
                        json[val.id] = { ...val, visible: false }
                    } else {
                        array.push(val)
                        json[val.id] = { ...val }
                    }
                })
                const order = orderby(array, ['idIncrement'], ['asc']);
                setData('chat', 'b76h9215l31otamc', json);
                setMessages(order);
            }
        });
    }

    useEffect(() => {
        getMessages();
        const dbRef = ref(db, `/chat/${idConferencia}/`);
        onChildChanged(dbRef, (data) => {
            getMessages();
        });

    }, [userID])

    const getMessages = async (e) => {
        const dataMessages = await getCollections(`chat/${idConferencia}/${userID}`);
        const order = orderby(dataMessages, ['idIncrement'], ['asc']);
        setMessages(order);
        console.log(order);
        dummy.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    const sendMessage = async (e) => {
        e.preventDefault();
        const dataUser = await getCollections('users');
        const infoUser = dataUser.filter(val => val.id === localStorage.getItem('3c393f98'));
        const id = uniqid();
        let json = {
            id,
            idIncrement: messages.length + 1,
            text: formValue,
            read: false,
            fechaDeRegistro: getDate(),
            user: localStorage.getItem('3c393f98'),
            nombre: `${infoUser[0].nombre} ${infoUser[0].apellido}`
        }
        // setMessages([...messages, json])
        setData(`chat/${idConferencia}/${userID}`, id, json).then(() => {
            getMessages()
        });
        setFormValue('');
    }

    return <>
        <main id="main">
            {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} vetarUsuario={vetarUsuario} idConferencia={idConferencia} />)}
            <span ref={dummy}></span>
        </main>

        <form onSubmit={sendMessage}>
            <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Escribe un mensaje..." />
            <button type="submit" disabled={!formValue}><SendIcon /></button>
        </form>
    </>
}

function ChatMessage(props) {
    const { text, user, fechaDeRegistro, nombre, visible, id, idIncrement } = props.message;
    const { vetarUsuario, idConferencia } = props;
    const messageClass = user === localStorage.getItem('3c393f98') ? 'sent' : 'received';
    let nombreUser = nombre;
    let imgMessage = Avatar;
    Object.values(admins).map(((val) => {
        if (val === user) {
            nombreUser = NombreAdmin;
            imgMessage = Logo;
        }
    }))

    const vetarMessage = (idMessage) => {
        let json = {
            fechaDeRegistro,
            id,
            idIncrement,
            nombre,
            text,
            user,
            visible: !visible,
        }
        Swal.fire({
            title: "¿Deseas borrar el mensaje?",
            icon: "warning",
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#51647D",
            showCancelButton: true,
            cancelButtonText: 'cerrar'
        }).then((result) => {
            if (result.isConfirmed) {
                updateData(`chat/${idConferencia}`, idMessage, json);
            }
        });
    }

    const displayMessage = () => {
        let display = 'block';
        if (!visible) {
            display = 'none';
            if (admins.indexOf(localStorage.getItem('3c393f98')) >= 0 || localStorage.getItem('3c393f98') === user) {
                display = 'block';
            } 
        }
        return display;
    }

    const displayVetado = () => {
        let clase = '';
        // if (admins.indexOf(localStorage.getItem('3c393f98')) >= 0 && !visible) {
        //     clase = 'vetado';  
        // }
        return clase;
    }

    return (<>
        <div className={displayVetado()} style={{ display: `${displayMessage()}` }}>
            <span className={`${messageClass} fecha`}>{fechaDeRegistro}</span>
            <div className={`message ${messageClass}`}>
                <img className='hijos' src={imgMessage} alt="as" />
                <p>
                    <span className='bold'>
                        {nombreUser}<br />
                    </span>
                    {text}
                </p>
                {/* {admins.indexOf(localStorage.getItem('3c393f98')) >= 0 && <>
                    <div className='cursor' onClick={() => vetarMessage(id)}>
                        <Tooltip title="Borrar mensaje" placement="bottom-start">
                            <DeleteIcon />
                        </Tooltip>
                    </div>
                    <div className='cursor' onClick={() => vetarUsuario(user)}>
                        <Tooltip title="Bloquear usuario" placement="bottom-start">
                            <BlockIcon />
                        </Tooltip>
                    </div>
                </>} */}
            </div>
        </div>
    </>)
}