import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../config/firebase";
import { ref, onChildChanged } from "firebase/database";
import { Grid, Container, Button } from "@material-ui/core";
// import parse from "html-react-parser";
import { getCollections } from "../../../resources/js/functions";
import AnaCaro from '../../../resources/images/home/ana-caro-redondo.svg';

export default function SectionConferenceActive(props) {
  const navigate = useNavigate();
  const [conferenciaActiva, setConferenciaActiva] = useState({});
  const [conferencia, setConferencia] = useState(false);

  useEffect(() => {
    const dbRef = ref(db, "conferencias/");
    onChildChanged(dbRef, (data) => {
      getConferences();
    });
    getConferences();
  }, []);

  const getConferences = async () => {
    const array = [];
    let json = {}
    const data = await getCollections("conferencias");
    const speakers = await getCollections("speakers");
    const act = data.filter(val => val.activa === true);
    Object.values(act).map(val => {
      Object.values(speakers).map(valID => {
        if (parseInt(val.speakers) === valID.id) {
          json = {
            ...val,
            infoSpeaker: valID
          }
          array.push(json)
        }
      })
    })
    // const actSpeaker = speaker.filter(val => val.activa === true);
    // // Object.values(data).map((val) => {
    if (Object.values(act).length > 0) {
      setConferenciaActiva(array[0]);
      setConferencia(true);
    } else {
      setConferencia(false);
    }
  }
  // // });
  // };

  return (
    <div id="section-kick-off" className="wow fadeInUp" data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
      <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          {!conferencia && <div className="contenedor-streaming">
            <iframe title="streaming" src="https://player.vimeo.com/video/706729600?h=3457956db3&badge=0&autopause=0&player_id=0&app_id=58479" allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming"></iframe>
          </div>}
          {conferencia && (
            <Container maxWidth="lg">
              <Grid container direction="row" alignItems="stretch" spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <div className="contenedor-streaming">
                    <iframe title="streaming" src={conferenciaActiva.urlVideo} allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming"></iframe>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className="target-conference">
                    <div className="name-conference bold">{conferenciaActiva.nombreConferencia}</div>
                    <div className="full padre">
                      <img className="hijos" style={{ borderRadius: '50%', width: '70px', height: '70px', margin: '10px 0' }} src={conferenciaActiva.infoSpeaker.urlFotoCuadrada} alt="img" />
                      <div className="hijos">
                        {conferenciaActiva.infoSpeaker.nombre}
                        <span className="hijos">{conferenciaActiva.infoSpeaker.puesto}</span>
                      </div>
                    </div>
                    <div className="full">
                      <div className="name-conferencista full padre">
                        <span className="instrucciones bold hijos" style={{ display: "flex" }}>
                          <span>
                            Ingresa a la sala de este espacio, participa en el chat y conoce más información
                            <br />
                            <br />
                            <span className="job-conferencista">
                              Haz clic en{" "}
                              <span style={{ textDecoration: "underline", cursor: "pointer", fontSize: "15px", color: '#1426ed' }} onClick={() => navigate(`/streaming/${conferenciaActiva.idUniqid}`)}>
                                Entrar
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
