import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Grid, TextField, Button } from "@material-ui/core";
import Swal from "sweetalert2";
import { validateEmail } from "../../../resources/js/functions";

export default function FormReset(props) {
  const { activeView } = props;
  const [campos, setCampos] = useState({});
  const [error, setError] = useState({ noUser: false, correo: false });
  // const navigate = useNavigate();
  const auth = getAuth();

  const validar = async (e) => {
    e.preventDefault();
    if (campos.correo) {
      if (!validateEmail(campos.correo)) {
        setError({ ...error, correo: "Ingresa un correo valido" });
        return null;
      }
    } else {
      setError({ ...error, correo: "El campo correo electrónico esta vacio." });
      return null;
    }

    const minusculas = campos.correo.toLowerCase();
    sendPasswordResetEmail(auth, minusculas)
      .then(() => {
        Swal.fire({
          title: "¡Bien hecho!",
          text: "El correo se envio correctamente",
          icon: "success",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#f38f04",
        }).then((result) => {
          activeView(1);
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("Error #" + errorCode);
        console.log("Error " + errorMessage);
        Swal.fire({
          title: "Error",
          text: "Contacta a un administrador",
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#f38f04",
        });
      });
  };

  return (
    <Grid item xs={12} sm={6} md={7} lg={7}>
      <form onSubmit={validar}>
        <div className="container-form-login">
          <div className="">
            <div className="text-form">Recuperar contraseña</div>
            {error.correo && (
              <div className="error" style={{ marginBottom: "30px" }}>
                <i className="fas fa-exclamation-triangle" /> {error.correo}
              </div>
            )}
            <div className="text-reset">Ingresa el correo electrónico asosiado a la cuenta, te enviaremos un lunk para reestablecer la contraseña</div>
            <TextField type="email" fullWidth size="small" label="Correo electrónico" variant="outlined" onChange={(e) => setCampos({ ...campos, correo: e.target.value })} />
            &nbsp;
            <div className="full space-footer-form">
              <Button type="submit" className="btn-naranja">
                Enviar
              </Button>
              <span className="register">
                <span className="register-text" onClick={() => activeView(1)}>
                  Regresar
                </span>
              </span>
            </div>
          </div>
        </div>
      </form>
    </Grid>
  );
}
