import React from "react";
import { Grid, Button } from "@material-ui/core";
import parse from "html-react-parser";

export default function SpeakersConfertencias(props) {
  const speakersActive = props.speakersActive;
  // console.log(speakersActive);
  return (
    <>
      <Grid item lg={12} md={12} xs={12}>
        <div className="titulos">Acerca del experto</div>
      </Grid>
      {Object.keys(speakersActive).map((val, index) => {
        const info = speakersActive[val]
        // console.log(info.urlFoto);
        return (
          <Grid item lg={12} md={12} xs={12} key={index}>
            <div className="full">
              <div className="name-conferencista full ">
                {/* <div style={{backgroundImage: `url(${info.urlFoto})`}}> */}
                <div >
                  <img className="img-conferencista" style={{ borderRadius: '50%' }} src={info.urlFotoCuadrada} alt="img" />
                </div>
                <span className="tendencias bold" style={{ display: "inline-block" }}>
                  <span className="job-conferencista">
                    <span className="name">{`${info.nombre}`}</span> <br />
                    <span className="job">
                      {info.puesto} - {info.empresa}
                    </span>
                    <br />
                    <br />
                    <span className="description-conferencista">{parse(info.descripcion)}</span>
                  </span>
                </span>
              </div>
            </div>
          </Grid>
        );
      })}
    </>
  );
}
