import React, { useState, useEffect } from 'react';
import { ref, onChildChanged } from "firebase/database";
import { db } from "../../config/firebase";
import { Container, Grid } from '@mui/material';
import { getCollectionsComplete, getCollections, getDate, setData, updateData } from '../../resources/js/functions';
import orderby from 'lodash.orderby';
import groupBy from "lodash.groupby";
import Avatar from '../../resources/images/chat/avatar.jpeg';
import Chat from './ChatPanel';
import Logo from '../../resources/images/chat/spira-logo.svg';
import './styles.scss';

export default function PanelChat() {
    const idConferencia = 'b76h9215l31otamc';
    const [msgs, setMsgs] = useState([]);
    const [userID, setUserID] = useState(null);

    useEffect(() => {
        getMessages();
        const dbRef = ref(db, `/chat/${idConferencia}/`);
        onChildChanged(dbRef, (data) => {
            getMessages();
        });
    }, [])

    const getMessages = async () => {
        let array = [];
        const data = await getCollectionsComplete(`chat/${idConferencia}`);
        const dataUser = await getCollectionsComplete(`users`);
        Object.keys(data).map(key => {
           array.push(dataUser[key]);
        })
        setMsgs(array);
    }


    return <>
        <div id='panel-chat'>
            <Container maxWidth='xl'>
                <Grid container alignItems="stretch" direction="row">
                    <Grid item lg={3} md={3} xs={3}>
                        <div className='contenedor-mensajes'>
                            {Object.values(msgs).map(val => {
                                return <>
                                    <div className='contenedor-mensaje-usuario noLeido' onClick={() => setUserID(val.id)}>
                                        <div className="padres">
                                            <img src={Avatar} alt="logo" className='hijoss' />
                                            <span className='hijoss'>{`${val.nombre} ${val.apellido}`}</span>
                                        </div>
                                    </div>
                                </>
                            })}
                        </div>
                    </Grid>
                    <Grid item lg={9} md={9} xs={9}>
                        <div className='visor-mensajes'>
                            <Chat idConferencia={idConferencia} userID={userID} />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    </>
}