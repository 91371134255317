// import { ref, onValue, set, update, remove, onChildChanged, orderByChild, equalTo, get, query } from "firebase/database";
// import { db } from "../../config/firebase";
import { searchData, cambiarTextos, getDate, getCollections } from "../../resources/js/functions";
let xlsx = require("json-as-xlsx");
const administradores = ['l2rwm5a9'];

// reporte de usuarios registrados
export async function reporteUsuarios() {
  const usuarios = await getCollections("users");
  const valuesUsuarios = Object.values(usuarios);
//   const array = [];
//   Object.values(valuesUsuarios).map((val) => {
//    if (administradores.indexOf(val.id) === -1) {
//         array.push(val);
//    }    
//   });

  // const results = await getCollections("encuestas", id);

  const fecha = cambiarTextos(getDate());
  let data = [
		{
			sheet: 'Usuarios',
			columns: [
				// { label: 'ID', value: 'id' }, // Top level data
				{ label: 'Nombre', value: 'nombre' }, // Top level data
				{ label: 'Apellido', value: 'apellido' }, // Top level data
				{ label: 'Correo electrónico', value: 'correo' }, // Top level data
				{ label: 'Cargo', value: 'cargo' }, // Top level data
				{ label: 'Fecha de registro', value: 'fechaDeRegistro' }, // Top level data
				{ label: 'Ultima conexión', value: 'ultimaConexion' }, // Top level data
			],
			content: valuesUsuarios,
		},
	];

  let settings = {
    fileName: `usuarios-${fecha}`, // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
  };

  xlsx(data, settings);
}

