import React from 'react';
import { Grid, Container, Button } from "@material-ui/core";
import ValidateUser from "../../components/ValidateUser";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SectionClientes from './SectionsSocios';
import Logo from '../../resources/images/header/logo.svg'
import './style.scss';

export default function AbotUs(params) {
    return <ValidateUser>
        <Header />
        <div id="aboutUs">
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <div className="full center">
                            <img src={Logo} alt="logo" className='logo-about-us' />
                        </div>
                    </Grid>
                    <Grid item lg={10} md={10} xs={12}>
                        <p>
                            <span className='bold' style={{ fontSize: '22px' }}>Primer plataforma mexicana</span> dedicada a crear, fomentar y mantener ambientes laborales saludables y equipos de trabajos más productivos y efectivos.<br /><br />
                            Brindamos programas integrales basados en 3 aspectos: cuerpo, mente y crecimiento. Nuestros programas están avalados científicamente.<br /><br />
                            Mindful Care ofrece experiencias virtuales, presenciales o hibrído para todo tipo de organizaciones.<br />
                            <br />
                            <br />
                            <span className='bold' style={{ fontSize: '22px' }}>Nuestro diferenciador:</span> Nuestros programas son <span className='bold'>PERSONALIZADOS</span> para cada organización, nos adaptamos a sus formas de trabajo, necesidades y brindamos un ecosistema flexible y al alcance de todos.<br /><br />
                            Contamos con un equipo de maestros certificados ante el IMTA y el Intituto Mexicano Mindfulness, certificación de Maestro Yoga por Yoga Alliance, The International Coach Federation, NOM-035.<br /><br />
                            También contamos con la certificación de Disney Institute, Harvard, Yale, Stanford, y la Universidad de Massachusets, y hemos sido reconocidos en distintos medios de comunicación tanto en México como en el extranjero. Llevamos más de 10 años de experiencia.
                            Hemos dado clases en Harvard, Ipade, CIDE, Linkedin, UNID y 3 de nuestros conferencistas han impartido 5 Ted Talks.
                        </p>
                    </Grid>
                </Grid>
            </Container>
            <SectionClientes />
            <br />
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <a href="mailto:info@mindfulcaro.com" style={{ margin: 'auto', textDecoration: 'none', color: '#fff' }}>
                            <div className="btn btn-one center" style={{ margin: 'auto' }}>
                                Escríbenos
                            </div>
                        </a>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <Footer />
    </ValidateUser>
}