// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyCQ6HqSYf4fd3gHzTKlTtia5TSaRZ3Z7to',
	authDomain: 'segob-mindfulness.firebaseapp.com',
	databaseURL: 'https://segob-mindfulness-default-rtdb.firebaseio.com',
	projectId: 'segob-mindfulness',
	storageBucket: 'segob-mindfulness.appspot.com',
	messagingSenderId: '375154802228',
	appId: '1:375154802228:web:cfc8d55816cc7a07cf1461',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app)
