import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Container } from "@material-ui/core";
import ValidateUser from "../../components/ValidateUser";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LogoCircular from "../../resources/images/speakers/logo-circular.png";
import FotoConferencista from "../../resources/images/speakers/foto-conferencista.png";
import Linkedin from "../../resources/images/footer/linkedin.png";
import Facebook from "../../resources/images/footer/facebook.png";
import Instagram from "../../resources/images/footer/instagram.png";
import "./styles.scss";
import { getCollections, top } from "../../resources/js/functions";



export default function Speakers() {
  const [speakers, setSpeakers] = useState([]);

  useEffect(() => {
    top();
    getSpeakers();
  }, [])

  const getSpeakers = async () => {
    const data = await getCollections('speakers');
    setSpeakers(data);
  }

  return (
    <ValidateUser>
      <Header />
      <div id="speakers" className="wow fadeInUp" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <div className="titulos">¡Descubre nuestros expertos y conferencistas!</div>
              <p>
                Contamos con un equipo de maestros certificados ante el IMTA (Organismo mundial que avala a los maestros de mindfulness de  nivel profesional) y el Instituto Mexicano de Mindfulness ( quien es la única institución que avala a nivel profesional en México), certificación de maestro Yoga por Yoga Alliance, The international Coach Federation y nom-035.


              </p>
              <p>
                También contamos con la certificación de Disney Institute, Harvard, Yale, Stanford y la Universidad de Massachusets, y hemos sido reconocidos en distintos medios de comunicación tanto en México como el extranjero. Llevamos más de 15 años de experiencia.

              </p>
              <p>
                Hemos dado  clases en Harvard, Ipade, CIDE, LinkedIn Learning, UNID y  3 de nuestros líderes han impartido 5 Ted Talks.

              </p>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>

                {Object.values(speakers).map((val, index) => (
                  <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                    <div className="container-speaker">
                      <div className="container-img">
                        <div style={{
                          backgroundImage: `url(${val.urlFotoCuadrada})`
                        }} className="img-conferencista" />
                      </div>
                      {val.urlEmpresaLogo !== ' ' && <div className="container-img-logo"><img src="https://via.placeholder.com/80" alt="logo" className="logo" /></div>}
                      <div className="name">{val.nombre}</div>
                      <div className="job">{val.empresa !== ' ' ? `${val.puesto} - ${val.empresa}` : `${val.puesto}`}</div>
                      <div className="social-media">
                        <img src={Instagram} alt="social.img" onClick={() => window.open(val.instagram, "_blank")} />
                      </div>
                    </div>
                  </Grid>
                ))}

              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </ValidateUser>
  );
}
