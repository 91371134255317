import React, { useState, useEffect } from "react";
import { ref, onChildChanged } from "firebase/database";
import { db } from "../../../config/firebase";
import { Grid, Container } from "@material-ui/core";
import orderby from 'lodash.orderby';

import { getCollections } from '../../../resources/js/functions';
import Carousel from "./Carousel";

export default function SectionConferencetoday() {
  const [conferencias, setConferencias] = useState([]);

  useEffect(() => {
    getConferencias();
    const dbRef = ref(db, "conferencias/");
    onChildChanged(dbRef, (data) => {
      getConferencias();
    });
  }, []);


  const getConferencias = async () => {
    const array = [];
    const data = await getCollections('conferencias', 'carousel', true);
    Object.values(data).map(val => {
      if (val.carousel && val.mes === "MAYO") {
        array.push(val);
      }
    })
    // console.log(array);
    const order = orderby(array, ['id'], ['desc']);
    setConferencias(order);
  }

  return (
    <>
      {conferencias.length > 0 && <>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <h1>Cursos para el autocuidado y bienestar   </h1>
            </Grid>
          </Grid>
        </Container>
        <div id="section-last-event" className="wow fadeInRight" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <Carousel />
              </Grid>
            </Grid>
          </Container>
        </div>
      </>}
    </>

  );
}
