import React, { useState, useEffect } from "react";
import { db } from "../../config/firebase";
import { ref, onChildChanged } from "firebase/database";
import { PropTypes } from "prop-types";
import { Toolbar } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Stack, Button, Snackbar } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import Menu from "../Menu";
import IconAlert from "../../resources/images/header/icon-alert.png";
import "./styles.scss";

const styles = () => ({
  grow: {
    flexGrow: 1,
  },
});

const Header = (props) => {
  const { children, bg } = props;
  const [alerta, setAlerta] = useState({ nombreConferencia: "" });
  const [seActiva, setSeActiva] = useState(false);
  const [alertaShow, setAlertaShow] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "right",
  });
  const navigate = useNavigate();
  const dbRef = ref(db, "conferencias/");
  const { vertical, horizontal, open } = alertaShow;

  useEffect(() => {
    nuevaConferencia();
    onChildChanged(dbRef, (data) => {
      if (data.val().activa && !data.val().encuesta) {

        setAlertaShow({
          open: true,
          vertical: "bottom",
          horizontal: "right",
        });

      }
      nuevaConferencia();
    });
    const header = document.getElementById("myHeader");
    // const navega = document.getElementById("menuHeader");
    const imgBanner = document.getElementById('imgBanner');
    // const sticky = header.offsetTop;

    window.addEventListener("scroll", (event) => {
      let scroll = window.scrollY;
      if (scroll > 0) {
        header.classList.add("bg-azul");
        // navega.classList.add("text-header-sticky");
        imgBanner.classList.add('transform-img');
      } else {
        if (!bg) {
          header.classList.remove("bg-azul");
          // navega.classList.remove("text-header-sticky");
        }
        imgBanner.classList.remove('transform-img');
      }
    });
    // const scrollCallBack = window.addEventListener('scroll', () => {
    // });
    // return () => {
    // 	window.removeEventListener('scroll', scrollCallBack);
    // };
  }, []);

  const nuevaConferencia = () => {
    onChildChanged(dbRef, (data) => {
      const nuevaConferencia = data.val();
      setAlerta(nuevaConferencia);
      // if (seActiva === false) {
      //   setSeActiva(true);
      //   setAlertaShow({
      //     open: true,
      //     vertical: "bottom",
      //     horizontal: "right",
      //   });
      // }
    });
    // const ref = firebase.database().ref(`conferencias`);
    // let nuevaConferencia = false;
    // ref.on("child_changed", function (snapshot) {
    //   nuevaConferencia = snapshot.val();
    //   console.log(nuevaConferencia);
    //   setAlerta(nuevaConferencia);
    // });
  };

  const handleClose = (event, reason) => {
    setAlertaShow({ ...alertaShow, open: false });
  };

  const action = (
    <Button color="secondary" size="small">
      <AccessAlarmIcon />
    </Button>
  );

  return (
    <>
      <Toolbar id="myHeader" className={`header mb60 ${bg ? "bg-azul" : ""}`}>
        <Menu bg={bg} />

        <div className={styles.grow} />
      </Toolbar>

      {/* alerta */}
      {/* <Stack spacing={2}>
        <Snackbar open={open}  anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={handleClose} action={action} onClick={() => navigate(`/streaming/1`)}>
          <div className="center alert padre">
            <img src={IconAlert} alt="icon" className="hijos" />
            <span className="hijos" style={{ textAlign: "initial" }}>
              <span className="title">
                Transmitiendo en este momento <br />
              </span>
              <span className="title-conference">{`${alerta.nombreConferencia.substring(0, 50)}...`}</span>
            </span>
          </div>
        </Snackbar>
      </Stack> */}
    </>
  );
};
Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  bg: PropTypes.bool,
};
export default Header;
