const array = [
	{
		texto: 'Home',
		link: '/home',
	},
	{
		texto: 'Talleres',
		link: '/talleres',
	},
	{
		texto: 'Conócenos',
		link: '/conocenos',
	},
	{
		texto: 'Conferencistas',
		link: '/conferencistas',
	},
	{
		texto: 'Cerrar sesión',
		link: '',
	},
];

export default array;
