import React from "react";
import { Grid, Container, Button } from "@material-ui/core";
import { downloadFile } from "../../../resources/js/functions";
import PDF from "../../../resources/descargables/certificado.pdf";
import iconoPrograma from '../../../resources/images/home/icono-programa.svg';
import "./styles.scss";

export default function SectionWhatSeeToday(props) {


  return (
    <>
      <div id="section-two" className="wow fadeInUp" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='1s'>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
            {/* <Grid item xs={12} sm={12} md={10} lg={10}>
              <div className="full center">
                <img src={iconoPrograma} alt="icono" className="icono-programa" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <div className="center">
                <p>Conoce el programa que tenemos preparado para ti</p>
              </div>
            </Grid> */}
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <div className="full center">
                <Button className="btn btn-one" onClick={() => downloadFile(PDF, 'Certificado Mindfulcare')} >
                  Descargar certificado
                </Button>
              </div>
            </Grid>
          </Grid>

        </Container>
      </div>
      {/* <Container maxWidth="lg" className="wow fadeInUp" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='1s'>
        <SectionConferenceActive />
      </Container> */}
    </>
  );
}
