import React from 'react';
import { Grid } from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import  imagenes from './clientes.json';

export default function Carousel() {
	return (
		<Grid item lg={12} md={12} sm={12} xs={12}>
			<AliceCarousel
				mouseTrackingEnabled
				items={imagenes}
				autoPlayInterval='2000'
				autoPlay={true}
				infinite={true}
				startIndex={0}
				slideToIndex={0}
				disableButtonsControls
				// disableDotsControls
				responsive={{
					425: {
						items: 1,
					},
					640: {
						items: 2,
					},
					1024: {
						items: 3,
					},
				}}
			>
				{imagenes.map((i, index) => (
					<div className='full center'>
						<img
							key={JSON.stringify(index)}
							className={`${i.efecto ? `logo-aliado` : ''} cursor`}
							onClick={i.url !== '' ? () => window.open(i.url, '_blank') : null}
							src={i.url}
							alt={`banner-${i.img}`}
						/>
					</div>
				))}
			</AliceCarousel>
		</Grid>
	);
}
