import React, { useState, useEffect } from "react";
// import firebase from "firebase";
import { ref, onChildChanged } from "firebase/database";
import { db } from "../../config/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Container, Button } from "@material-ui/core";
import parse from "html-react-parser";
import ValidateUser from "../../components/ValidateUser";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SpeakersConferencias from "./SpeakersConferencias";
import { searchData, getCollections, top, } from "../../resources/js/functions";
import IconoDescargable from '../../resources/images/streamingOnLine/icono-descargable.svg'
import IconoDudas from '../../resources/images/streamingOnLine/icono-dudas.svg'
import "./styles.scss";

export default function StreamingOnLine() {
  const params = useParams();

  const [dataInfo, setDataInfo] = useState([]);
  const [speakersActive, setSpeakersActive] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getVideo();
    top();
    const dbRef = ref(db, "conferencias/");
    onChildChanged(dbRef, (data) => {
      getVideo();
    });
  }, []);

  const getVideo = async () => {
    const result = await searchData(`conferencias/`, params.id);
    const speakersResults = await getCollections("speakers");
    setDataInfo(result);
    const infoSpeaker = speakersResults.filter(val => val.id === result.speakers);
    setSpeakersActive(infoSpeaker);
  };


  return (
    <>
      <ValidateUser>
        <Header />
        <div id="streamingOnLine">
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
              <Grid item lg={12} md={12} xs={12}>
                <div className="titulos">{dataInfo.nombreConferencia}</div>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                {parse(`${dataInfo.descripcion !== undefined && dataInfo.descripcion}`)}
              </Grid>
              <Grid item lg={8} md={6} xs={12}>
                <div className="contenedor-streaming">
                  <iframe title="streaming" src={dataInfo.urlVideo} allow="autoplay; fullscreen" frameBorder="0" allowFullScreen className="estilo-streaming"></iframe>
                </div>
              </Grid>
              {dataInfo.urlChat !== '' && <Grid item lg={4} md={6} xs={12}>
                <div className="contentenedor-chat">
                  <iframe title="chat" src={dataInfo.urlChat} width="100%" height="100%" frameBorder="0"></iframe>
                </div>
              </Grid>}
              <SpeakersConferencias speakersActive={speakersActive} />
            </Grid>
          </Container>
          <div id="section-two" className="wow fadeInUp" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='1s'>
            <Container maxWidth="lg">
              <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                {dataInfo.urlDescargable !== ' ' && <Grid item xs={12} sm={12} md={5} lg={5}>
                  <div className="full center">
                    <img src={IconoDescargable} alt="icono" className="icono-descargable" />
                    <p>Material complementario</p>
                    <Button className="btn btn-one" style={{ margin: 'auto' }} onClick={() => window.open(dataInfo.urlDescargable, '_blank')}>
                      Descárgalo
                    </Button>
                  </div>
                </Grid>}
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <div className="full center">
                    <img src={IconoDudas} alt="icono" className="icono-descargable" />
                    <p>¿Tienes dudas?</p>
                    <a href="mailto:info@mindfulcaro.com" style={{ margin: 'auto', textDecoration: 'none', color: '#fff' }}>
                      <div className="btn btn-one center" style={{ margin: 'auto' }}>
                        Escríbenos
                      </div>
                    </a>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
          <Footer />
        </div>
      </ValidateUser>
    </>
  );
}
